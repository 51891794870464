import React from 'react';
import { JumpmanIcon } from 'src/components/svgs';

function Wnz3HeroMobile({ images }) {
  const [img01] = images;

  return (
    <div className="wnz3-hero">
      <div className="wnz3-hero__container">
        <div className="wnz3-hero__header">
          <JumpmanIcon modifier="wnz3-hero__header-logo" />
          <h3 className="main-heading wnz3-hero__header-hdg">
            WHY NOT 0.3
          </h3>
          <p className="main-copy wnz3-hero__header-copy">
            JORDAN BRAND UNITE
          </p>
        </div>
      </div>
      <img
        className="wnz3-hero__img"
        src={img01}
        alt=""
      />
    </div>
  )
}

export default Wnz3HeroMobile;