import React from 'react';
// components
import { Parallax } from 'react-scroll-parallax';
import ParallaxImage from 'src/components/parallax/ParallaxImage';

function Wnz3ImpactMobile({ images }) {
  const [img10, img11, img12] = images;
  return (
    <div className="wnz3-impact">
      <Parallax className="wnz3-impact__heading" y={[0, 0]}>
      {/* <Parallax className="wnz3-impact__heading" y={[40, -80]}> */}
        <h3 className="impact">
          Impact Impact
        </h3>
      </Parallax>
      <p className="wnz3-impact__copy wnz3-impact__copy--1">
        Responses across social media
        were extremely powerful and
        landed with the audience exactly
        as they were intended to.
      </p>
      <p className="wnz3-impact__copy wnz3-impact__copy--2">
        Russell himself shared multiple
        assets on his own channel,
        supporting the campaign and
        showing pride for the message it
        expressed to the world.
      </p>
      <Parallax className="wnz3-impact__blockquote" y={[0, 0]}>
      {/* <Parallax className="wnz3-impact__blockquote" y={[80, -20]}> */}
        <blockquote className="blockquote">
          &ldquo; …but rather a powerful message to inspire and empower youth.&rdquo;
        </blockquote>
      </Parallax>

      <div className="global-box-shadow wnz3__img wnz3__img--7">
        <img src={img10} alt="" />
      </div>

      <Parallax className="wnz3__img wnz3__img--8" y={[0, 0]}>
      {/* <Parallax className="wnz3__img wnz3__img--2" y={[20, -40]}> */}
        <ParallaxImage className="global-box-shadow" src={img11} alt="" />
      </Parallax>

      <div className="global-box-shadow wnz3__img wnz3__img--9">
        <img src={img12} alt="" />
      </div>
    </div>
  );
}

export default Wnz3ImpactMobile;