import React from 'react';
// components
import { Parallax } from 'react-scroll-parallax';
import VideoPlayer from 'src/components/VideoPlayer';

function Wnz3ChallengeMobile({ images, videos }) {
  const [
    img02,
    img03,
  ] = images;
  const [vid01] = videos;

  return (
    <div className="wnz3-challenge">

      <h3 className="challenge wnz3-challenge__heading wnz3-challenge__heading--1">
        Challenge
      </h3>

      <p className="wnz3-challenge__copy wnz3-challenge__copy--1">
        How can we create a campaign to support the release of Russell Westbrook’s 3rd signature shoe, while also launching the newly accomp-anying apparel line - in a way that is true to Russell and the community the product was made for?
      </p>

      <p className="wnz3-challenge__tagline wnz3-challenge__tagline--1">
        <span>THE HERO FILM</span>
        :60 Film that told the overall
        campaign narrative.
      </p>

      <div className="wnz3__img wnz3__img--2">
        <img src={img02} alt="girl" />
      </div>

      <p className="wnz3-challenge__img-caption">
        Photo: Jamal Burger
      </p>

      <VideoPlayer
        className="wnz3__vid wnz3__vid--1"
        src={vid01}
        poster={img03}
      />

      <Parallax className="wnz3-challenge__heading wnz3-challenge__heading--2" y={[0, 0]}>
      {/* <Parallax className="wnz3-challenge__heading wnz3-challenge__heading--2" y={[40, -80]}> */}
        <h3 className="solution">
          Solution
        </h3>
      </Parallax>

      <p className="wnz3-challenge__copy wnz3-challenge__copy--2">
        A campaign based on Westbrook’s life and widely known Why Not!? Mindset - inspired and anchored in the Southside LA neighborhood Westbrook grew up in.
      </p>
    </div>
  )
}

export default Wnz3ChallengeMobile;