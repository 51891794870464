import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import SectionTrigger from 'src/components/SectionTrigger';
import VideoPlayer from 'src/components/VideoPlayer';
import { JumpmanIcon } from 'src/components/svgs';

function Wnz3Content({ images, videos }) {
  const [
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
    img11,
    img12,
  ] = images;
  const [vid01, vid02, vid03] = videos;

  return (
    <div>
      <SectionTrigger bgColor="#eff5fa" textColor="#000">
        <Wnz3Hero images={[img01]} />
      </SectionTrigger>
      <div className="wnz3__container">
        <Wnz3Challenge images={[img02, img03]} videos={[vid01]} />
        <Wnz3Execution
          images={[img04, img05, img06, img07, img08, img09]}
          videos={[vid02, vid03]}
        />
        <Wnz3Impact images={[img10, img11, img12]} />
      </div>
      <SectionTrigger
        bgColor="#eff5fa"
        className="wnz3__section wnz3__section--1"
        textColor="#000"
      />
      <SectionTrigger
        bgColor="#de7b68"
        className="wnz3__section wnz3__section--2"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#eff5fa"
        className="wnz3__section wnz3__section--3"
        textColor="#000"
      />
    </div>
  );
}

function Wnz3Hero({ images }) {
  const [img01] = images;

  return (
    <div className="wnz3-hero">
      <div className="wnz3-hero__container">
        <div className="wnz3-hero__header">
          <JumpmanIcon modifier="wnz3-hero__header-logo" />
          <h3 className="main-heading wnz3-hero__header-hdg">
            WHY NOT 0.3
          </h3>
          <p className="main-copy wnz3-hero__header-copy">
            <span>JORDAN BRAND</span>UNITE
          </p>
        </div>
      </div>
      <img
        className="wnz3-hero__img"
        src={img01}
        alt=""
      />
    </div>
  )
}

function Wnz3Challenge({ images, videos }) {
  const [img02, img03] = images;
  const [vid01] = videos;

  return (
    <div className="wnz3-challenge">
      <h3 className="challenge wnz3-challenge__heading wnz3-challenge__heading--1">
        Challenge
      </h3>

      <p className="wnz3-challenge__copy wnz3-challenge__copy--1">
        How can we create a campaign to support the
        release of Russell Westbrook’s 3rd signature shoe,
        while also launching the newly accompanying
        apparel line - in a way that is true to both the
        superstar and the specific consumers the product
        was made for?
      </p>

      <p className="wnz3-challenge__tagline wnz3-challenge__tagline--1">
        <span>THE HERO FILM</span>
        :60 Film that told the overall
        campaign narrative.
      </p>

      <div className="wnz3__img wnz3__img--2">
        <img src={img02} alt="girl" />
      </div>

      <p className="wnz3-challenge__img-caption">
        Photo: Jamal Burger
      </p>

      <VideoPlayer
        className="wnz3__vid wnz3__vid--1"
        src={vid01}
        poster={img03}
      />

      <Parallax className="wnz3-challenge__heading wnz3-challenge__heading--2" y={[0, 0]}>
      {/* <Parallax className="wnz3-challenge__heading wnz3-challenge__heading--2" y={[40, -80]}> */}
        <h3 className="solution">
          Solution
        </h3>
      </Parallax>

      <p className="wnz3-challenge__copy wnz3-challenge__copy--2">
        A campaign based on Westbrook’s life and
        widely known Why Not!? mindset, a philosophy
        that underscores maintaining a positive
        attitude no matter the adversity you face - as
        well as the name of his foundation - started to
        empower and inspire youth in underprivileged
        communities around the world through that
        same mindset. Featuring a combination of his
        friends, family and real people - all from the
        same South Central neighborhood Westbrook
        grew up in - and shot on location in that same
        neighborhood.
      </p>
    </div>
  );
}

function Wnz3Execution({ images, videos }) {
  const [img04, img05, img06, img07, img08, img09] = images;
  const [vid02, vid03] = videos;

  return (
    <div className="wnz3-execution">
      <VideoPlayer
        className="wnz3__vid wnz3__vid--2"
        src={vid02}
        poster={img04}
      />

      <p className="wnz3-execution__tagline wnz3-execution__tagline--1">
        <span>FAMILY & FRIENDS</span>
        Longform content film featuring Russell Westbrook, his
        family and friends sharing real life stories and their
        connection to the WHY NOT? Mindset
      </p>

      <Parallax className="wnz3-execution__heading" y={[0, 0]}>
      {/* <Parallax className="wnz3-execution__heading" y={[40, -80]}> */}
        <h3 className="execution">
          Execution
        </h3>
      </Parallax>

      <p className="wnz3-execution__copy">
        After initially concepting we worked closely
        with Russell Westbrook himself to further
        refine the details of the campaign to ensure it
        was most authentic to him. We then cast real
        people from his actual childhood
        neighborhood, as well as close friends and
        family and then shot on location in that same
        neighborhood to ensure the story was as raw
        and real as it could be.
      </p>

      <blockquote className="blockquote wnz3-execution__blockquote">
        &ldquo;A radically authentic marketing effort that is more than a campaign…&rdquo;
      </blockquote>

      <p className="wnz3-execution__tagline wnz3-execution__tagline--2">
        <span>REGAN ALIYAH</span>
        Longform content film featuring the rapper, spoken word artist, performing an extended version of the spoken word piece performed
        in the hero film.
      </p>

      <VideoPlayer
        className="wnz3__vid wnz3__vid--3"
        src={vid03}
        poster={img05}
      />

      <div className="wnz3__img wnz3__img--3">
        <img src={img06} alt="" />
      </div>

      <div className="wnz3__img wnz3__img--4">
        <img src={img07} alt="" />
      </div>

      <p className="wnz3-execution__img-caption">
        Photo: Jamal Burger
      </p>

      <Parallax
        className="wnz3__img wnz3__img--5"
        y={[0, 0]}
        // y={[40, -20]}
      >
        <ParallaxImage src={img08} alt="" />
      </Parallax>

      <div className="wnz3__img wnz3__img--6">
        <img src={img09} alt="" />
      </div>
    </div>
  );
}

function Wnz3Impact({ images }) {
  const [img10, img11, img12] = images;
  return (
    <div className="wnz3-impact">
      <Parallax className="wnz3-impact__heading" y={[0, 0]}>
      {/* <Parallax className="wnz3-impact__heading" y={[40, -80]}> */}
        <h3 className="impact">
          Impact Impact
        </h3>
      </Parallax>
      <p className="wnz3-impact__copy wnz3-impact__copy--1">
        Responses across social media
        were extremely powerful and
        landed with the audience exactly
        as they were intended to.
      </p>
      <p className="wnz3-impact__copy wnz3-impact__copy--2">
        Russell himself shared multiple
        assets on his own channel,
        supporting the campaign and
        showing pride for the message it
        expressed to the world.
      </p>
      <Parallax className="wnz3-impact__blockquote" y={[0, 0]}>
      {/* <Parallax className="wnz3-impact__blockquote" y={[80, -20]}> */}
        <blockquote className="blockquote">
          &ldquo; …but rather a powerful message to inspire and empower youth.&rdquo;
        </blockquote>
      </Parallax>

      <div className="global-box-shadow wnz3__img wnz3__img--7">
        <img src={img10} alt="" />
      </div>

      <Parallax className="wnz3__img wnz3__img--8" y={[0, 0]}>
      {/* <Parallax className="wnz3__img wnz3__img--2" y={[20, -40]}> */}
        <ParallaxImage className="global-box-shadow" src={img11} alt="" />
      </Parallax>

      <div className="global-box-shadow wnz3__img wnz3__img--9">
        <img src={img12} alt="" />
      </div>
    </div>
  );
}

export default Wnz3Content;
