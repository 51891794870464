import React from 'react';
// components
import { Parallax } from 'react-scroll-parallax';
import ParallaxImage from 'src/components/parallax/ParallaxImage';
import VideoPlayer from 'src/components/VideoPlayer';

function Wnz3ExecutionMobile({ images, videos }) {
  const [img04, img05, img06, img07, img08, img09] = images;
  const [vid02, vid03] = videos;

  return (
    <div className="wnz3-execution">
      <VideoPlayer
        className="wnz3__vid wnz3__vid--2"
        src={vid02}
        poster={img04}
      />
      <p className="wnz3-execution__tagline wnz3-execution__tagline--1">
        <span>FAMILY & FRIENDS</span>
        Longform content film featuring Russell Westbrook, his
        family and friends sharing real life stories and their
        connection to the WHY NOT? Mindset
      </p>
      <Parallax className="wnz3-execution__heading" y={[0, 0]}>
      {/* <Parallax className="wnz3-execution__heading" y={[40, -80]}> */}
        <h3 className="execution">
          Execution
        </h3>
      </Parallax>

      <p className="wnz3-execution__copy">
        After initially concepting we worked closely with Russell Westbrook himself to further refine the details of the campaign to ensure it was most authentic to him. We then cast real people from his actual childhood neighbor-hood, as well as close friends and family and then shot on location there.
      </p>

      <blockquote className="blockquote wnz3-execution__blockquote">
        &ldquo;A radically authentic marketing effort that is more than a campaign…&rdquo;
      </blockquote>

      <p className="wnz3-execution__tagline wnz3-execution__tagline--2">
        <span>REGAN ALIYAH</span>
        Longform content film featuring the rapper, spoken word artist, performing an extended version of the spoken word piece performed in the hero film.
      </p>

      <VideoPlayer
        className="wnz3__vid wnz3__vid--3"
        src={vid03}
        poster={img05}
      />

      <div className="wnz3__img wnz3__img--3">
        <img src={img06} alt="" />
      </div>

      <div className="wnz3__img wnz3__img--4">
        <img src={img07} alt="" />
      </div>

      <p className="wnz3-execution__img-caption">
        Photo: Jamal Burger
      </p>

      <Parallax
        className="wnz3__img wnz3__img--5"
        y={[0, 0]}
        // y={[40, -20]}
      >
        <ParallaxImage src={img08} alt="" />
      </Parallax>

      <div className="wnz3__img wnz3__img--6">
        <img src={img09} alt="" />
      </div>
    </div>
  )
}

export default Wnz3ExecutionMobile;