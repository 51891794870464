import React from 'react';
// components
import Wnz3ChallengeMobile from './Wnz3ChallengeMobile';
import Wnz3ExecutionMobile from './Wnz3ExecutionMobile';
import Wnz3HeroMobile from './Wnz3HeroMobile';
import Wnz3ImpactMobile from './Wnz3ImpactMobile';
import SectionTrigger from '../SectionTrigger';

function Wnz3ContentMobile({ images, videos }) {
  const [
    img01,
    img02,
    img03,
    img04,
    img05,
    img06,
    img07,
    img08,
    img09,
    img10,
    img11,
    img12,
  ] = images;
  const [vid01, vid02, vid03] = videos;

  return (
    <div>
      <SectionTrigger bgColor="#eff5fa" textColor="#000">
        <Wnz3HeroMobile images={[img01]} />
      </SectionTrigger>
      <div className="wnz3__container">
        <Wnz3ChallengeMobile images={[img02, img03]} videos={[vid01]} />
        <Wnz3ExecutionMobile images={[img04, img05, img06, img07, img08, img09]} videos={[vid02, vid03]} />
        <Wnz3ImpactMobile images={[img10, img11, img12]} />
      </div>
      <SectionTrigger
        bgColor="#eff5fa"
        className="wnz3__section wnz3__section--1"
        textColor="#000"
      />
      <SectionTrigger
        bgColor="#de7b68"
        className="wnz3__section wnz3__section--2"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#eff5fa"
        className="wnz3__section wnz3__section--3"
        textColor="#000"
      />
    </div>
  )
}

export default Wnz3ContentMobile;