import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import 'src/scss/wnz3.scss';
// hooks
import wnz3Media from 'src/hooks/wnz3Media';
import wnz3MobileMedia from 'src/hooks/wnz3MobileMedia';
import useMediaQuery from 'src/hooks/useMediaQuery';
// lib
import handlePageTransition from 'src/lib/handle-page-transition';
// components
import SEO from 'src/components/SEO';
import Wnz3Content from 'src/components/wnz3/Wnz3Content';
import MobileWnz3Content from 'src/components/wnz3/Wnz3ContentMobile';

function Wnz3Page({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const mobileImages = [];
  const images = [];
  const mobileMedia = wnz3MobileMedia();
  const media = wnz3Media();

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));
  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  const vid01 =
    'https://player.vimeo.com/external/433468205.sd.mp4?s=3e6dc17cfca9401727784da572b65643eb0c4954&profile_id=164&oauth2_token_id=1365998561';
  const vid02 =
    'https://player.vimeo.com/external/433468242.sd.mp4?s=4143c27994e79487ad8e3e0d0f15b7e7c46db91b&profile_id=165&oauth2_token_id=1365998561';
  const vid03 =
    'https://player.vimeo.com/external/433468256.hd.mp4?s=ae192f72a6ba6d4886475bb04f5b1e0e6b584d83&profile_id=174&oauth2_token_id=1365998561';

  return (
    <div>
      <SEO title="Why Not 0.3 | Falkon Content" />
      <div className="wnz3">
        {isDesktop
          ? (<Wnz3Content images={images} videos={[vid01, vid02, vid03]} />)
          : (<MobileWnz3Content images={mobileImages} videos={[vid01, vid02, vid03]} />)
        }
      </div>
    </div>
  );
}

export default React.memo(Wnz3Page);
